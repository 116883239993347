import React, { Component } from "react";
import up from "../images/up.svg";
import down from "../images/down.svg";
import one from "../images/1.svg";
import two from "../images/2.svg";
import three from "../images/3.svg";
import four from "../images/4.svg";
import five from "../images/5.svg";
import six from "../images/6.svg";
import seven from "../images/7.svg";
import { withRouter } from "react-router-dom";

class LoanProcessFlow extends Component {
  constructor() {
    super();
    this.state = {
      processData: [
        {
          process: "Register your Institute",
          image: one,
          details:
            "Fill basic details of your Institute to register for the program ( Registered Name, Registration Number, Contact Details and Course information)",
          btn: "Register Now",
        },
        {
          process:
            "Finalization of payment structure & e-signing of agreement with our lending partner",
          image: two,
          details:
            "Choose the suitable payment structure you would like to offer to your students and e-signing of the agreement with our partner lenders. Loan tenure cannot be more than the duration of the course.",
          btn: "",
        },
        {
          process: "Institute Dashboard Creation",
          image: three,
          details:
            "Our lending partner will give you access to a dashboard where you can see details of your students who have applied for the loan and their loan status. You can also fill student details on your dashboard or add them right away.",
          btn: "Add students’ details",
        },
        {
          process: "Lending Partner contacts students within 2 working days",
          image: four,
          details:
            "The student would be contacted by our lending partner to discuss the loan process within 2 working days",
          btn: "",
        },
        {
          process: "Submission of documents by the student (T Day)",
          image: five,
          details:
            "The students/Co-Applicants can either share their documents with our lending partner over email or upload the documents in the application form shared by the institute themselves. The documents should be of the person on whose name the loan has been applied.",
          btn: "",
        },
        {
          process: "Loan Approval within 2 days (T+2 days)",
          image: six,
          details:
            "Our lending partner will verify the loan application and approve the loan. The students will be contacted in case any other information is required.",
          btn: "",
        },
        {
          process: "Loan disbursed to the Institute within 2 days (T+4 days)",
          image: seven,
          details:
            "Once the loan is approved, our lending partner will send the loan agreement to the student. The loan will be disbursed to the Institute after the student has paid the first monthly installment.",
          btn: "",
        },
      ],
      moreClick: false,
      activeIndex: "",
    };
  }

  seeMore = (index) => {
    if (index === this.state.activeIndex) {
      this.setState({ activeIndex: "" });
    } else {
      this.setState({ activeIndex: index });
    }
  };

  render() {
    const { processData, moreClick, activeIndex } = this.state;
    return (
      <div className="LoanProcessFlow">
        <div className="LoanProcessFlow__Header">Loan Process Flow</div>
        <div className="LoanProcessFlow__Flow">
          {processData.map((data, index) => {
            return (
              <div key={index} className="LoanProcessFlow__Flow--Content">
                <img
                  className="LoanProcessFlow__Flow--Content__Number"
                  src={data.image}
                  alt={data.image}
                />
                <div className="LoanProcessFlow__Flow--Content__BodyDiv">
                  <div
                    onClick={() => this.seeMore(index)}
                    className="LoanProcessFlow__Flow--Content__Div"
                  >
                    <div className="LoanProcessFlow__Flow--Content__Div--Para">
                      {data.process}
                    </div>
                    <img
                      className="LoanProcessFlow__Flow--Content__Div--ArrowDown"
                      src={!moreClick ? down : up}
                      alt="arrow"
                    />
                  </div>
                  <div
                    style={index !== activeIndex ? { display: "none" } : {}}
                    className="LoanProcessFlow__Flow--ContentDetails"
                  >
                    <div className="LoanProcessFlow__Flow--ContentDetails__Para">
                      {data.details}
                    </div>
                    {data.btn !== "" ? (
                      <button
                        className="LoanProcessFlow__Flow--ContentDetails__Button"
                        onClick={() => {
                          data.btn === "Register Now"
                            ? this.props.history.push("/join-program")
                            : this.props.history.push("/student-details");
                        }}
                      >
                        {data.btn}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <button
          className="LoanProcessFlow__Button"
          onClick={() => {
            this.props.history.push("/join-program");
          }}
        >
          Register Now
        </button>
        {
          //    <div className="LoanProcessFlow__AlreadyRegistered">
          //   Already registered? You can{" "}
          //   <span
          //     style={{ color: "#009ae0", cursor: "pointer" }}
          //     onClick={() => {
          //       this.props.history.push("/student-details");
          //     }}
          //   >
          //     add details of students
          //   </span>{" "}
          //   who wish to avail EMI facility
          // </div>
        }
      </div>
    );
  }
}

export default withRouter(LoanProcessFlow);
