import React, { Component } from "react";
import StudentModal from "./StudentModal";
import { Link } from "react-router-dom";
import SuccesBg from "../images/successBg.svg";
import SuccesSmall from "../images/successSmall.svg";
import close from "../images/Close.svg";
import Navigation from "./Navigation";
import services from "../services";
import StudentDetailsSuccess from "./StudentDetailsSuccess"

class StudentDetails extends Component {
  constructor() {
    super();
    this.state = {
      isSuccess: false,
      stateResponse: [],
      cityResponse: [],
      token: ''
    };
  }

  componentDidMount() {
    // this.stateApiCall();
    // this.cityApiCall();
    const token = localStorage.getItem('accessToken')
    if (token) {
      this.setState({
        token
      })
    }
  }

  displaySuccess = () => {
    this.setState({ isSuccess: !this.state.isSuccess });
  };

  // stateApiCall = async () => {
  //   const stateData = await services.getApiCall(`su/affiliate/state`);
  //   this.setState({ stateResponse: stateData.data.data.states });
  // };

  // cityApiCall = async () => {
  //   const cityData = await services.getApiCall(
  //     `su/affiliate/state?city=1`
  //   );
  //   this.setState({ cityResponse: cityData.data.data.city });
  // };

  render() {
    return !this.state.isSuccess ? (
      <div className="GivenLeads">
        <Navigation />
        <div className="GivenLeads__Back">
          <Link to="/">
            <button>Back</button>
          </Link>
        </div>
        <div className="GivenLeads__Head">Student Details for EMI facility</div>
        <div className="GivenLeads__Modal">
          <StudentModal
            displaySuccess={this.displaySuccess}
            stateResponse={this.state.stateResponse}
            cityResponse={this.state.cityResponse}
            xAccessToken={this.state.token}
          />
        </div>
      </div>
    ) : (
        <div className="SuccessMsgLeads">
          {window.screen.width > 700 ? <Navigation /> : ""}
          <StudentDetailsSuccess displaySuccess={this.displaySuccess} />
          {/*<div className="SuccessMsg__modalSuccess">
            <div className="SuccessMsg__modalContainerSuccess">
              <div className="SuccessMsg__modalBodySuccess">
                <div className="SuccessMsg__modalBodySuccess--successClose">
                  <Link to="/">
                    <img
                      alt="close"
                      src={close}
                      onClick={this.props.closeSuccessMsg}
                    />
                  </Link>
                </div>
                <img
                  className="SuccessMsg__modalBodySuccess--img"
                  alt="success"
                  src={SuccesSmall}
                />
                <p className="SuccessMsg__modalBodySuccess--successPara">
                  Thank you for joining and giving leads. The programs' success is
                  all because of you!
              </p>
              </div>
            </div>
          </div>
          <img className="SuccessMsgLeads__Img" src={SuccesBg} alt="SuccesBg" />
          <label className="SuccessMsgLeads__Para">
            Thank you for joining and giving leads The programs' success is all
            because of you!
          </label>*/}
        </div>
      );
  }
}

export default StudentDetails;
