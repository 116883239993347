import React, { Component } from "react";
import ScrollToTop from "./ScrollToTop";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./components/Home";
import JoinEMIProgram from "./components/JoinEMIProgram"
import StudentDetails from "./components/StudentDetails";
import EMIProgramSuccess from "./components/EMIProgramSuccess";

class App extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/join-program" component={JoinEMIProgram} />
              <Route exact path="/student-details" component={StudentDetails} />
              <Route exact path="/success" component={EMIProgramSuccess} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
