import React, { Component } from 'react';
import rupee from "../images/rupee.svg"
import per from "../images/per.svg"

class FinalFormCourse extends Component {
    constructor(){
        super();
        this.state = {

        }
    }

    changeCourseName = (e) => {
        const {currentIndex} = this.props
        this.props.updateCourseField(currentIndex,"courseName",e)
    }

    changeCourseDuration = (e) => {
        const {currentIndex} = this.props
        this.props.updateCourseField(currentIndex,"courseDuration",e)
    }

    changeCourseFees = (e) => {
        const {currentIndex} = this.props
        this.props.updateCourseField(currentIndex,"courseFees",e)
    }

    changeCourseTaxes = (e) => {
        const {currentIndex} = this.props
        this.props.updateCourseField(currentIndex,"applicableTaxes",e)
    }

    render(){
        const {currentIndex,courseList,coursefield} = this.props
        return(
            <div className="FinalFormCourse" style={{marginBottom: "2.4rem"}}>
                <div className="JoinEMIProgram__Container--Form__SubLabel" style={{marginBottom:"2.4rem",fontSize:"1.2rem"}}>COURSE {currentIndex + 1}
                {courseList.length > 1 && currentIndex !== 0 && (
                    <span onClick={()=>{this.props.removeFieldFromCourseList(currentIndex)}} className="removeButton">
                        · REMOVE
                    </span>
                )}
                </div>
                <div className="JoinEMIProgram__Container--Form__Label">Course Name</div>
                <input
                    id="name"
                    type="string"
                    value={coursefield.courseName}
                    onChange={this.changeCourseName} 
                    className="JoinEMIProgram__Container--Form__Input" 
                    placeholder="eg. Foundation Course" 
                />
                <div className="JoinEMIProgram__Container--Form__Label">Course Duration (Months)</div>
                <select
                    id="duration"
                    type="string"
                    value={coursefield.courseDuration}
                    onChange={this.changeCourseDuration}
                    className="JoinEMIProgram__Container--Form__Select">
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                        <option>06</option>
                        <option>07</option>
                        <option>08</option>
                        <option>09</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                </select>
                <div className="JoinEMIProgram__Container--Form__FlexRow">
                    <div className="JoinEMIProgram__Container--Form__FlexRow--Col">
                        <div className="JoinEMIProgram__Container--Form__Label">Course Fees</div>
                        <div className="JoinEMIProgram__Container--Form__FlexRow--InputDiv">
                            <img src={rupee} alt="in" className="JoinEMIProgram__Container--Form__FlexRow--InputDiv--IN" />
                            <input
                                id="fees"
                                type="number"
                                value={coursefield.courseFees}
                                onChange={this.changeCourseFees} 
                                className="JoinEMIProgram__Container--Form__FlexRow--InputDiv__Input" 
                                placeholder="" />
                        </div>
                    </div>
                    <div className="JoinEMIProgram__Container--Form__FlexRow--Col">
                        <div className="JoinEMIProgram__Container--Form__Label MarginMobileTop">Applicable Taxes</div>
                        <div className="JoinEMIProgram__Container--Form__FlexRow--InputDiv">
                            <input
                                id="taxes"
                                type="number"
                                value={coursefield.applicableTaxes}
                                onChange={this.changeCourseTaxes} 
                                className="JoinEMIProgram__Container--Form__FlexRow--InputDiv__Input" 
                                placeholder="" />
                            <img src={per} alt="in" className="JoinEMIProgram__Container--Form__FlexRow--InputDiv--PER" />
                        </div>
                    </div>
                </div>
                {courseList.length - 1 === currentIndex && courseList.length < 10 && (
                    <div className="JoinEMIProgram__Container--Form__Footer">
                        <div className="JoinEMIProgram__Container--Form__Footer--Para">Want to add more courses? {currentIndex + 1} of 10 added</div>
                        <button onClick={this.props.addFieldToCourseList} className="JoinEMIProgram__Container--Form__Footer--Button">+ ADD</button>
                    </div>
                )}
            </div>
        )
    }
}

export default FinalFormCourse;