import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import Navigation from './Navigation';
import check from "../images/check.svg";
import tick from "../images/tick.svg";
import next from "../images/next.svg"
import mobileSmall from "../images/mobileSmall.svg";

class EMIProgramSuccess extends Component {
    constructor(){
        super()
        this.state = {
        }
    }

    render(){
        return(
            <div className="PaddingBorder">
                {window.screen.availWidth < 700 ? "" : <Navigation />}
                <div className="EMIProgramSuccess__MobileHeader">
                    <img onClick={this.gotBack} className="EMIProgramSuccess__MobileHeader--Next" src={next} alt="next" />
                    <img className="EMIProgramSuccess__MobileHeader--Logo" src={mobileSmall} alt="mobileSmall" />
                </div>
                <div className="EMIProgramSuccess">
                    <div className="EMIProgramSuccess__Header">Join Classplus Student EMI Program</div>
                    <div className="EMIProgramSuccess__Container">
                        <img src={check} alt="check" className="EMIProgramSuccess__Container--Para1" />
                        <div className="EMIProgramSuccess__Container--Para2">You have successfully joined Classplus Student EMI Program</div>
                        <div className="EMIProgramSuccess__Container--Para3">Classplus team or our associated lenders will get in touch to verify the details & onboard your Institute</div>
                        <div  className="EMIProgramSuccess__Container--Para4">Meanwhile, you can add details of students who wish to avail EMI</div>
                        <button onClick={()=>{this.props.history.push("/student-details")}} className="EMIProgramSuccess__Container--Button">Add students’ details</button>
                        <div className="EMIProgramSuccess__Container--Footer">
                            <div className="EMIProgramSuccess__Container--Footer__Content">
                                <img src={tick} alt="tick" className="EMIProgramSuccess__Container--Footer__Content--Img"/>
                                <div className="EMIProgramSuccess__Container--Footer__Content--Para">Once onboarded, you will have a unique link to send to your students to apply for EMI scheme. You will also have your own dashboard to track all student applications.</div>
                            </div>
                            <div className="EMIProgramSuccess__Container--Footer__Content">
                                <img src={tick} alt="tick" className="EMIProgramSuccess__Container--Footer__Content--Img"/>
                                <div className="EMIProgramSuccess__Container--Footer__Content--Para">Classplus team or our associated lenders will assist your students in their registration & documentation process.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(EMIProgramSuccess);