import React, { useState } from "react";
import IN from "../images/in.svg";
import { toast } from "react-toastify";
import downArrow from "../images/downArrow.svg";

function StudentModalBody({
  leadsfield,
  currentIndex,
  leadsList,
  updateLeadField,
  addFieldToLeadsList,
  removeFieldFromLeadsList,
  addClicked,
  removeClicked,
  stateResponse,
  cityResponse,
}) {
  const [showNumberError, handleNumberError] = useState(false);
  const [showNameError, handleNameError] = useState(false);
  const [showEmailError, handleEmailError] = useState(false);
  const [showInstituteError, handleInstituteError] = useState(false);
  const [showCityError, handleCityError] = useState(false);
  const [showStateError, handleStateError] = useState(false);
  const [isCoApplicant, handleCoApplicant] = useState(false);

  const changeLeadName = (e) => {
    updateLeadField(currentIndex, "leadName", e);
  };

  const changeLeadNumber = (e) => {
    if (e.target.value.length <= 10) {
      updateLeadField(currentIndex, "leadNumber", e);
    }
  };

  const changeLeadEmail = (e) => {
    updateLeadField(currentIndex, "leadEmail", e);
  };

  const changeCoApplicantRequired = (boo) => {
    updateLeadField(currentIndex, "coApplicantRequired", boo ? "Yes" : "No");
    handleCoApplicant(boo);
  };

  const changeCoApplicantName = (e) => {
    updateLeadField(currentIndex, "coApplicantName", e);
  };

  const changeCoApplicantNumber = (e) => {
    updateLeadField(currentIndex, "coApplicantNumber", e);
  };

  const changeCoApplicantMail = (e) => {
    updateLeadField(currentIndex, "coApplicantMail", e);
  };

  const removeFromLeadsList = () => {
    removeFieldFromLeadsList(currentIndex);
    removeClicked();
  };

  const addToLeadsList = () => {
    if (!showNameError) {
      addFieldToLeadsList();
      addClicked();
    } else {
      toast.error("Please fill valid Name ,Number or Student Name");
    }
  };

  const numberError = (e) => {
    let mobileFormat = new RegExp(/^[6-9]$/);
    if (e.target.value.length < 10) {
      handleNumberError(true);
    } else if (e.target.value.length === 10) {
      handleNumberError(false);
    }
    if (mobileFormat.test(e.target.value.charAt(0))) {
      handleNumberError(false);
    } else {
      handleNumberError(true);
    }
  };

  const nameError = (e) => {
    if (e.target.value.trim().length < 3) {
      handleNameError(true);
    } else {
      handleNameError(false);
    }
  };

  return (
    <div className="LeadsModal__modalBody">
      <div className="LeadsModal__modalBody-StudentIndex">
        STUDENT {currentIndex + 1}
        {leadsList.length > 1 && currentIndex !== 0 && (
          <span onClick={removeFromLeadsList} className="removeButton">
            {" "}
            REMOVE{" "}
          </span>
        )}
      </div>
      {/*<div className="LeadsModal__modalBody--instituteHead">
        <span>
          Coaching Institute {leadsList.length > 1 ? "-" : ""}{" "}
          {leadsList.length > 1 ? currentIndex + 1 : ""}
        </span>

      </div>
      <div
        className="LeadsModal__modalBody--institute"
        style={{ marginBottom: "2.1rem" }}
      >
        <input
          id="institute"
          type="string"
          value={leadsfield.instituteName}
          onChange={changeInstituteName}
          placeholder="eg. Aditya Coaching Institute "
          onBlur={instituteError}
          autoComplete="off"
        />
      </div>
      {showInstituteError ? (
        <div className="LeadsModal__modalBody--instituteError">
          * Institute Name Not Valid
        </div>
      ) : (
          ""
      )}*/}
      <div className="LeadsModal__modalBody--nameHead">Student Name</div>
      <div
        className="LeadsModal__modalBody--name"
        style={{ marginBottom: "2.1rem" }}
      >
        <input
          id="name"
          type="string"
          value={leadsfield.leadName}
          onChange={changeLeadName}
          placeholder="eg. Abhinav Kumar"
          onBlur={nameError}
          autoComplete="off"
        />
      </div>
      {showNameError === true ? (
        <div className="LeadsModal__modalBody--nameError">* Name Not Valid</div>
      ) : (
        ""
      )}

      <div className="LeadsModal__modalBody--coApplicantContentHeading">
        Is a co-applicant required?
      </div>
      <div className="LeadsModal__modalBody--coApplicantContentText">
        If student is NOT salaried/doesn’t own a business, a co-applicant
        (student’s parent / sibling / guardian) is required in whose name the
        loan application will be processed
      </div>

      <div className="LeadsModal__modalBody--coApplicantRadioGroup">
        <div className="LeadsModal__modalBody--coApplicantRadioGroup-radio">
          <input
            type="radio"
            name="radio"
            defaultChecked
            onChange={() => {
              changeCoApplicantRequired(false);
            }}
            className="LeadsModal__modalBody--coApplicantRadioGroup-radioBtn"
          />
          <label>No</label>
        </div>
        <div className="LeadsModal__modalBody--coApplicantRadioGroup-radio">
          <input
            type="radio"
            name="radio"
            onChange={() => {
              changeCoApplicantRequired(true);
            }}
            className="LeadsModal__modalBody--coApplicantRadioGroup-radioBtn"
          />
          <label>Yes</label>
        </div>
      </div>

      {!isCoApplicant ? (
        <div>
          <div className="LeadsModal__modalBody--MobileHead">
            Student’s Contact Number
          </div>
          <div
            className="LeadsModal__modalBody--MobileNumber"
            style={{ marginBottom: "2.1rem" }}
          >
            <img className="LeadsModal__modalBody--IN" src={IN} alt="IN" />
            <input
              id="mobile"
              value={leadsfield.leadNumber}
              type="number"
              onChange={changeLeadNumber}
              pattern="[0-9]*"
              placeholder="eg. 9898989898"
              onBlur={numberError}
            />
          </div>
          {showNumberError === true ? (
            <div className="LeadsModal__modalBody--PhoneError">
              * Phone Number Not Valid
            </div>
          ) : (
            ""
          )}

          <div className="LeadsModal__modalBody--EmailHead">
            Student’s Email ID
          </div>
          <div
            className="LeadsModal__modalBody--name"
            style={{ marginBottom: "2.1rem" }}
          >
            <input
              value={leadsfield.leadEmail}
              type="email"
              onChange={changeLeadEmail}
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              placeholder="eg. abhinavkumar@gmail.com"
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="LeadsModal__modalBody--nameHead">
            Co-applicant’s Name
          </div>
          <div
            className="LeadsModal__modalBody--name"
            style={{ marginBottom: "2.1rem" }}
          >
            <input
              id="name"
              type="string"
              value={leadsfield.coApplicantName}
              onChange={changeCoApplicantName}
              placeholder="eg. Arjun Kumar"
              autoComplete="off"
            />
          </div>

          <div className="LeadsModal__modalBody--MobileHead">
            Co-applicant’s Contact Number
          </div>
          <div
            className="LeadsModal__modalBody--MobileNumber"
            style={{ marginBottom: "2.1rem" }}
          >
            <img className="LeadsModal__modalBody--IN" src={IN} alt="IN" />
            <input
              id="mobile"
              value={leadsfield.coApplicantNumber}
              type="number"
              onChange={changeCoApplicantNumber}
              pattern="[0-9]*"
              placeholder="eg. 9898989898"
            />
          </div>
          {showNumberError === true ? (
            <div className="LeadsModal__modalBody--PhoneError">
              * Phone Number Not Valid
            </div>
          ) : (
            ""
          )}

          <div className="LeadsModal__modalBody--EmailHead">
            Co-applicant’s Email ID
          </div>
          <div
            className="LeadsModal__modalBody--name"
            style={{ marginBottom: "2.1rem" }}
          >
            <input
              id="mobile"
              value={leadsfield.coApplicantMail}
              type="email"
              onChange={changeCoApplicantMail}
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              placeholder="eg. abhinavkumar@gmail.com"
              // onBlur={numberError}
            />
          </div>
          {showEmailError === true ? (
            <div className="LeadsModal__modalBody--PhoneError">
              * Email Not Valid
            </div>
          ) : (
            ""
          )}
        </div>
      )}

      {/*<div className="LeadsModal__modalBody--cityHead">City</div>
      <div
        className="LeadsModal__modalBody--city"
        style={{ marginBottom: "2.1rem" }}
      >
        <select
          value={leadsfield.city}
          onChange={changeCityName}
          onBlur={cityError}
        >
          <option value="select">--select--</option>
          {cityResponse.map((data, index) => {
            return <option key={index}>{data.city}</option>;
          })}
        </select>
        <img src={downArrow} alt="downArrow" />
      </div>
      {showCityError === true ? (
        <div className="LeadsModal__modalBody--cityError">
          * City Name Not Valid
        </div>
      ) : (
          ""
        )}
      <div className="LeadsModal__modalBody--stateHead">State</div>
      <div className="LeadsModal__modalBody--state">
        <select
          value={leadsfield.state}
          onChange={changeStateName}
          onBlur={stateError}
        >
          <option value="select">--select--</option>
          {stateResponse.map((data, index) => {
            return <option key={index}>{data}</option>;
          })}
        </select>
        <img src={downArrow} alt="downArrow" />
      </div>
      {showStateError ? (
        <div className="LeadsModal__modalBody--stateError">
          * State Name Not Valid
        </div>
      ) : (
          ""
      )}
      <div className="LeadsModal__modalBody--RadioButton">
        <input onChange={changeAbove40} className="LeadsModal__modalBody--RadioButton__Btn" type="radio" />
        <div className="LeadsModal__modalBody--RadioButton__Para">I confirm that the details I have shared are of a coaching institute with over 40 students</div>
      </div>*/}
      {leadsList.length - 1 === currentIndex && leadsList.length < 10 && (
        <div className="LeadsModal__AddLeads">
          <label className="LeadsModal__AddLeads--Para">
            Want to give more Leads? {currentIndex + 1} of 10 added
          </label>
          <button
            onClick={addToLeadsList}
            className="LeadsModal__AddLeads--Btn"
          >
            {"+ "} {" Add"}
          </button>
        </div>
      )}
    </div>
  );
}

export default StudentModalBody;
