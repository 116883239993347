import React, { Component } from "react";
import sucessImage from "../images/sucessImage.svg"
import mobileLogo from "../images/mobileLogo.svg";
import { withRouter } from "react-router-dom";


class StudentDetailsSuccess extends Component {

    constructor() {
        super();
        this.state = {
        };
    }

    render() {
        return (
            <div className="StudentDetailsSuccess">
                <div className="StudentDetailsSuccess-mobileLogo">
                    <img src={mobileLogo} alt="" />
                </div>
                <div className="StudentDetailsSuccess--Heading">Student Details for EMI facility</div>
                <div className="StudentDetailsSuccess--Body">
                    <img src={sucessImage} alt="" />
                    <div className="StudentDetailsSuccess--Body-Row1">Student Details added successfully</div>
                    <div className="StudentDetailsSuccess--Body-Row2">Classplus team or our associated lenders will assist your students in their registration & documentation process.</div>
                    <div className="StudentDetailsSuccess--Body-Row3">Want to add details of more students?</div>
                    <div className="StudentDetailsSuccess--Body-Footer">
                        <button onClick={this.props.displaySuccess} className="StudentDetailsSuccess--Body-Footer-Btn">Add students’ details</button>
                    </div>
                </div>
            </div>
        );
    }


}

export default withRouter(StudentDetailsSuccess);
