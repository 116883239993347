import React, { Component } from "react";
import ClassplusLogo from "../images/classplusLogo.svg";
import { Link } from "react-router-dom";

class Navigation extends Component {
  render() {
    return (
      <div className="navigation">
        <div className="navigation__logo">
          <Link to="/">
            <img
              src={ClassplusLogo}
              alt="Classplus"
              className="navigation__logo--img"
            />
          </Link>
        </div>
        {this.props.isScroll ?
          window.location.href.includes("join-program") ? 
            ""
          : 

          <button
            style={{background:"#ffffff",color:"#009AE0",border:"1px solid #009AE0"}}
            onClick={this.props.openRegister}
            className="btn--bookADemo btn navigation--btn"
          >
            Register Now
          </button>
            
          : ""
        }
      </div>
    );
  }
}

export default Navigation;
