import React, { Component } from "react";
import Navigation from "./Navigation";
import ProgessBar from "./ProgressBar";
import FinalForm from "./FinalForm";
import india from "../images/in.svg";
import next from "../images/next.svg";
import mobileSmall from "../images/mobileSmall.svg";
import { withRouter } from "react-router-dom";

class JoinEMIProgram extends Component {
  constructor() {
    super();
    this.state = {
      step: 1,
      step1Data: [
        {
          label1: "Institute Name",
          field1: "eg. Chandra Classes",
          label2: "Contact Number",
          field2: "eg.  9898989898",
          subHeading:
            "Are there any students looking for EMI option for paying tuition fees?",
          radioButton: [
            { label: "1 to 5 students" },
            { label: "5 to 10 students" },
            { label: "More than 10 students" },
            { label: "Not now, maybe later" },
          ],
        },
      ],
      step2Data: [
        {
          label1: "Registered Institute Name",
          field1: "eg. Chandra Classes",
          label2: "Institute Registration Number",
          field2: "eg. CEPP10122",
          subHeading: "Type of Institute Registration",
          radioButton: [
            { label: "Company" },
            { label: "LLP" },
            { label: "Partnership Firm" },
            { label: "Proprietorship Firm" },
            { label: "Other" },
          ],
        },
      ],

      step1Values: {
        name: "",
        number: "",
        radioChoice: 1,
        radioLabel: "1 to 5 students",
      },
      step2Values: {
        name: "",
        number: "",
        radioChoice: 1,
        radioLabel: "Company",
      },
    };
  }

  field1Change = (e, index) => {
    const { step, step1Values, step2Values } = this.state;
    step == 1
      ? this.setState({
          step1Values: { ...step1Values, name: e.target.value },
        })
      : this.setState({
          step2Values: { ...step2Values, name: e.target.value },
        });
  };

  field2Change = (e, index) => {
    const { step, step1Values, step2Values } = this.state;
    step == 1
      ? this.setState({
          step1Values: { ...step1Values, number: e.target.value },
        })
      : this.setState({
          step2Values: { ...step2Values, number: e.target.value },
        });
  };

  radioChoiceChange = (e, index, label) => {
    const { step, step1Values, step2Values } = this.state;
    step == 1
      ? this.setState({
          step1Values: {
            ...step1Values,
            radioChoice: index + 1,
            radioLabel: label,
          },
        })
      : this.setState({
          step2Values: {
            ...step2Values,
            radioChoice: index + 1,
            radioLabel: label,
          },
        });
  };

  gotoNext = () => {
    const { step, step1Values, step2Values } = this.state;
    if (step === 1) {
      if (!step1Values.name || !step1Values.number) {
        return alert("Please enter valid full details");
      }
      this.setState({ step: 2 });
    } else if (step === 2) {
      if (!step2Values.name || !step2Values.number) {
        return alert("Please enter valid full details");
      }
      this.setState({ step: 3 });
      console.log("!!!!!!!!step1 ->", this.state.step1Values);
      console.log("!!!!!!!!step2 ->", this.state.step2Values);
    }
  };

  gotBack = () => {
    const { step } = this.state;
    if (step === 1) {
      this.props.history.push("/");
    } else if (step === 2) {
      this.setState({ step: 1 });
    } else if (step === 3) {
      this.setState({ step: 2 });
    }
  };

  submitTutorInformation = async (
    instituteDescription,
    courseList,
    modeOfTeaching
  ) => {
    const { step1Values, step2Values } = this.state;
    let { name, number } = step1Values;
    let numberOfStudents = step1Values.radioLabel;
    let regName = step2Values.name;
    let regNum = step2Values.number;
    let instituteType = step2Values.radioLabel;
    instituteDescription.forEach(function (v) {
      delete v.index;
    });
    localStorage.setItem("tutorName", name);
    localStorage.setItem("tutorNumber", number);

    let instituteDesc = instituteDescription.map((ins) => ins.label).join(",");

    for (const course of courseList) {
      try {
        const response = await fetch(
          "https://v1.nocodeapi.com/classplus/google_sheets/xjQkrinSFNkXNTCm?tabId=Sheet1",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify([
              [
                new Date().toLocaleString(),
                name,
                number,
                numberOfStudents,
                regName,
                regNum,
                instituteType,
                JSON.stringify(instituteDesc),
                JSON.stringify(modeOfTeaching),
                JSON.stringify(course.courseName),
                JSON.stringify(course.courseDuration),
                JSON.stringify(course.courseFees),
                JSON.stringify(course.applicableTaxes),
              ],
            ]),
          }
        );

        await response.json();
      } catch (err) {
        console.log(err);
      }
    }
    this.props.history.push("/success");
  };

  render() {
    const { step, step1Data, step2Data, step1Values, step2Values } = this.state;
    const renderingData = step === 1 ? step1Data : step2Data;
    return (
      <div className="JoinEMIProgram">
        {window.screen.availWidth < 700 ? "" : <Navigation />}
        <div className="JoinEMIProgram__MobileHeader">
          <img
            onClick={this.gotBack}
            className="JoinEMIProgram__MobileHeader--Next"
            src={next}
            alt="next"
          />
          <img
            className="JoinEMIProgram__MobileHeader--Logo"
            src={mobileSmall}
            alt="mobileSmall"
          />
        </div>
        <div className="JoinEMIProgram__Body">
          <div className="JoinEMIProgram__Header">
            <button
              onClick={this.gotBack}
              className="JoinEMIProgram__Header--Back"
            >
              Back
            </button>
            <div className="JoinEMIProgram__Header--Head">
              Join Classplus Student EMI Program
            </div>
            <div className="JoinEMIProgram__Header--Dummy">dummy</div>
          </div>
          <div className="JoinEMIProgram__Container">
            <ProgessBar step={step} />
            {step === 3 ? (
              <FinalForm submitTutorInformation={this.submitTutorInformation} />
            ) : (
              renderingData.map((data, index) => {
                return (
                  <div key={index} className="JoinEMIProgram__Container--Form">
                    <div className="JoinEMIProgram__Container--Form__Label">
                      {data.label1}
                    </div>
                    <input
                      className="JoinEMIProgram__Container--Form__Input"
                      placeholder={data.field1}
                      onChange={(e) => this.field1Change(e, index)}
                      value={step == 1 ? step1Values.name : step2Values.name}
                    />
                    <div className="JoinEMIProgram__Container--Form__Label">
                      {data.label2}
                    </div>
                    {step === 2 ? (
                      <div className="JoinEMIProgram__Container--Form__SubLabel">
                        Subject to verification (CIN/UIN/GSTIN/Others)
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="JoinEMIProgram__Container--Form__InputDiv">
                      {step == 1 ? (
                        <img
                          src={india}
                          alt="in"
                          className="JoinEMIProgram__Container--Form__InputDiv--IN"
                        />
                      ) : (
                        ""
                      )}
                      <input
                        className="JoinEMIProgram__Container--Form__InputDiv--Input"
                        placeholder={data.field2}
                        onChange={(e) => this.field2Change(e, index)}
                        value={
                          step == 1 ? step1Values.number : step2Values.number
                        }
                      />
                    </div>
                    <div className="JoinEMIProgram__Container--Form__SubHeading">
                      {data.subHeading}
                    </div>
                    {data.radioButton.map((dt, index) => {
                      return (
                        <div
                          className="JoinEMIProgram__Container--Form__RadioButton"
                          key={index}
                          onClick={() =>
                            this.radioChoiceChange(step, index, dt.label)
                          }
                        >
                          <input
                            className="JoinEMIProgram__Container--Form__RadioButton--Radio"
                            type="radio"
                            onClick={() =>
                              this.radioChoiceChange(step, index, dt.label)
                            }
                            name="radioChoice"
                            checked={
                              step == 1
                                ? step1Values.radioChoice == index + 1
                                : step2Values.radioChoice == index + 1
                            }
                          />
                          <div
                            className="JoinEMIProgram__Container--Form__RadioButton--Label"
                            onClick={() =>
                              this.radioChoiceChange(step, index, dt.label)
                            }
                          >
                            {dt.label}
                          </div>
                        </div>
                      );
                    })}
                    <button
                      onClick={this.gotoNext}
                      className="JoinEMIProgram__Container--Button"
                    >
                      Next
                    </button>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(JoinEMIProgram);
