import React, { Component } from 'react';

class ContactUs extends Component {
    render(){
        return(
            <div className="ContactUs">
                <div className="ContactUs__Header">Contact Us</div>
                <div className="ContactUs__Para">For any queries, please contact us at 
                <span className="darkColor"> +91-7355840566</span> or drop a mail to 
                <span className="darkColor"> studentemi@classplus.co</span></div>
            </div>
        )
    }
}

export default ContactUs;

// For any queries, feel free to reach us at +91-7355840566 or drop a mail to studentemi@classplus.co