import React, { Component } from "react";
import rupee from "../images/rupee.svg";
import rightArrow from "../images/rightArrow.svg";
import ellipse from "../images/ellipse.svg";

class FeesCalculator extends Component {
  constructor() {
    super();
    this.state = {
      fees: 15000,
      duration: 3,
      emiDuration: 3,
      amountReceived: 14499,
      monthlyEmi: 5000,
      amountPaidByStudent: 15000,
    };
  }

  handleFeesChange = (e) => {
    this.setState(
      {
        fees: e.target.value,
      },
      () => {
        this.updateFeesStructure();
      }
    );
  };

  handleDurationChange = (e) => {
    this.setState(
      {
        duration: e.target.value,
      },
      () => {
        this.updateFeesStructure();
      }
    );
  };

  handleEmiDurationChange = (e) => {
    this.setState(
      {
        emiDuration: e.target.value,
      },
      () => {
        this.updateFeesStructure();
      }
    );
  };

  updateFeesStructure = () => {
    const { fees, duration, emiDuration } = this.state;

    let subventionRate = 3.34;
    if (emiDuration >= 6 && emiDuration <= 8) {
      subventionRate = 6;
    } else if (emiDuration >= 9 && emiDuration <= 11) {
      subventionRate = 8.34;
    } else if (emiDuration >= 12) {
      subventionRate = 10.59;
    }
    this.setState({
      amountReceived: fees - (subventionRate * fees) / 100,
      monthlyEmi: fees / emiDuration,
      amountPaidByStudent: fees,
    });
  };

  render() {
    const {
      fees,
      duration,
      emiDuration,
      amountPaidByStudent,
      amountReceived,
      monthlyEmi,
    } = this.state;

    return (
      <div className="FeesCalculator">
        <div className="FeesCalculator__Working">
          <div className="FeesCalculator__Working--Header">How it works?</div>
          <div className="FeesCalculator__Working--Para">
            The institute will take a percentage cut on the loan amount,
            Subvention, to be able to offer financing options at zero cost EMI
            to students. The subvention would be charged on the loan amount at
            the time of disbursement of fee to the institute.{" "}
          </div>
        </div>
        <div className="FeesCalculator__Calculator">
          <div className="FeesCalculator__Calculator--Header">
            Fees Calculator
          </div>
          <div className="FeesCalculator__Calculator--Para">
            Calculate the monthly EMI your students will pay & the fee that you
            will get
          </div>
          <div className="Calculator">
            <div className="Calculator__Row1">
              <div className="Calculator__Row1--Col">
                <div className="Calculator__Row1--Col__Para">Course Fee</div>
                <div className="Calculator__Row1--Col__InputDiv">
                  <img
                    src={rupee}
                    alt="rupee"
                    className="Calculator__Row1--Col__InputDiv--Img"
                  />
                  <input
                    className="Calculator__Row1--Col__InputDiv--Input"
                    value={fees}
                    onChange={this.handleFeesChange}
                    type="number"
                  />
                </div>
              </div>
              <div className="Calculator__Row1--Col">
                <div className="Calculator__Row1--Col__Para">
                  Course Duration (in months)
                </div>
                <input
                  className="Calculator__Row1--Col__Input"
                  value={duration}
                  onChange={this.handleDurationChange}
                  type="number"
                />
              </div>
              <div className="Calculator__Row1--Col">
                <div className="Calculator__Row1--Col__Para">
                  EMI Duration (in months)
                </div>
                {duration > 0 && duration < 6 ? (
                  <select
                    className="Calculator__Row1--Col__Select"
                    value={emiDuration}
                    onChange={this.handleEmiDurationChange}
                  >
                    <option>3</option>
                  </select>
                ) : duration >= 6 && duration < 9 ? (
                  <select
                    className="Calculator__Row1--Col__Select"
                    value={emiDuration}
                    onChange={this.handleEmiDurationChange}
                  >
                    <option>3</option>
                    <option>6</option>
                  </select>
                ) : duration >= 9 && duration < 12 ? (
                  <select
                    className="Calculator__Row1--Col__Select"
                    value={emiDuration}
                    onChange={this.handleEmiDurationChange}
                  >
                    <option>3</option>
                    <option>6</option>
                    <option>9</option>
                  </select>
                ) : (
                  <select
                    className="Calculator__Row1--Col__Select"
                    value={emiDuration}
                    onChange={this.handleEmiDurationChange}
                  >
                    <option>3</option>
                    <option>6</option>
                    <option>9</option>
                    <option>12</option>
                  </select>
                )}
              </div>
            </div>
            <div className="Calculator__Row2">
              <div className="Calculator__Row2--Content1">
                <div className="Calculator__Row2--Details">
                  Amount received by Institute
                </div>
                <div className="Calculator__Row2--Number">
                  ₹ <span>{parseFloat(amountReceived).toFixed(2)}</span>
                </div>
              </div>
              <div className="Calculator__Row2--Content2">
                <div className="Calculator__Row2--Details">
                  Monthly Student EMI
                </div>
                <div className="Calculator__Row2--Number">
                  ₹ <span>{parseFloat(monthlyEmi).toFixed(2)}</span>
                </div>
              </div>
              <div className="Calculator__Row2--Content3">
                <div className="Calculator__Row2--Details">
                  Total amount paid by the Student
                </div>
                <div className="Calculator__Row2--Number">
                  ₹ <span>{parseFloat(amountPaidByStudent).toFixed(2)}</span>
                </div>
              </div>
            </div>
            <div className="Calculator__Row3">
              Subvention rates vary with EMI Duration
            </div>
            <div className="Calculator__Row4">
              <div className="Calculator__Row4--Rate">Subvention rate</div>
              <img
                className="Calculator__Row4--Arrow"
                src={rightArrow}
                alt="rightArrow"
              />
              <div className="Calculator__Row4--Value">3.34%</div>
              <div className="Calculator__Row4--Value">6.00%</div>
              <div className="Calculator__Row4--Value">8.34%</div>
              <div className="Calculator__Row4--Value">10.59%</div>
            </div>
            <div className="Calculator__Row5">
              <div className="Calculator__Row5--Blue"></div>
              <div className="Calculator__Row5--CircleDiv">
                <div className="Calculator__Row5--CircleDiv__CRate">rate</div>
                <div className="Calculator__Row5--CircleDiv__CArrow">ar</div>
                <div className="Calculator__Row5--CircleDiv__Circle">
                  <img src={ellipse} alt="ellipse" />
                </div>
                <div className="Calculator__Row5--CircleDiv__Circle">
                  <img src={ellipse} alt="ellipse" />
                </div>
                <div className="Calculator__Row5--CircleDiv__Circle">
                  <img src={ellipse} alt="ellipse" />
                </div>
                <div className="Calculator__Row5--CircleDiv__Circle">
                  <img src={ellipse} alt="ellipse" />
                </div>
              </div>
            </div>
            <div className="Calculator__Row6">
              <div className="Calculator__Row4--Rate">EMI Duration</div>
              <img
                className="Calculator__Row4--Arrow"
                src={rightArrow}
                alt="rightArrow"
              />
              <div className="Calculator__Row6--Value6">3 months</div>
              <div className="Calculator__Row6--Value6">6 months</div>
              <div className="Calculator__Row6--Value6">9 months</div>
              <div className="Calculator__Row6--Value6">12 months</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeesCalculator;
