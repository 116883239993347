import React, { Component } from 'react';
import step1 from "../images/step1.svg";
import step2 from "../images/step2.svg";
import step2N from "../images/step2N.svg";
import step3 from "../images/step3.svg";
import step3N from "../images/step3N.svg";
import stepCheck from "../images/stepCheck.svg";

class ProgessBar extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    render(){
        const {step} = this.props;
        return (
            <div className="ProgressBar">
                <div className="ProgressBar__Content--Step">
                    <img className="ProgressBar__Content--Step__Img" src={step === 1 ? step1 : stepCheck} />
                    <div className="ProgressBar__Content--Step__Para">Basic Details</div>
                </div>
                <div className="ProgressBar__Content--Dash"></div>
                <div className="ProgressBar__Content--Step">
                    <img className="ProgressBar__Content--Step__Img" src={step === 1 ? step2N : ( step === 2 ? step2 : stepCheck )} />
                    <div className="ProgressBar__Content--Step__Para">Institute Registration Details</div>
                </div>
                <div className="ProgressBar__Content--Dash"></div>
                <div className="ProgressBar__Content--Step">
                    <img className="ProgressBar__Content--Step__Img" src={step === 3 ? step3 : step3N} />
                    <div className="ProgressBar__Content--Step__Para">Courses Offered Details</div>
                </div>
            </div>
        )
    }
}

export default ProgessBar;