import React, { useState } from "react";

function FAQs({ modalOpen, scrollToRegister }) {
  const [showMore, handleMore] = useState(false);

  const showMoreFAQ = () => {
    if (showMore) {
      handleMore(false);
    } else {
      handleMore(true);
    }
  };

  return (
    <div className="faqContainer">
      <div className="faqContainer--heading">Frequently Asked Questions</div>
      <div className="faq">
        <input id="faq-a" type="checkbox" />
        <label for="faq-a">
          <div className="faq-heading">
            <div>
              <div>1.</div>
              <div>What is Classplus Student EMI programme ?</div>
            </div>
            <div className="faq-arrow"></div>
          </div>

          <div className="faq-text">
            Classplus aims to provide loans to the deserving and needy
            candidates at ZERO PERCENT interest so that they can easily pursue
            their dream career without any interest burden
            <br />
          </div>
        </label>
        <input id="faq-b" type="checkbox" />
        <label for="faq-b">
          <div className="faq-heading">
            <div>
              <div>2.</div>
              <div>How does Classplus provide these loans ?</div>
            </div>
            <div className="faq-arrow"></div>
          </div>

          <div className="faq-text">
            Classplus aims to tie up with various lending partners who provide
            these loans to the students. Any changes with respect to the
            application process & interest rates will be informed to the tutors
            in due course of time.
            <br />
            <br />
            For applications, where the amount is already disbursed with the
            current lender, things will be unchanged until the entire duration
            of the loan.
            <br />
          </div>
        </label>
        <input id="faq-c" type="checkbox" />
        <label for="faq-c">
          <div className="faq-heading">
            <div>
              <div>3.</div>
              <div>
                For what type of courses can the student apply for loan under
                the programme ?
              </div>
            </div>
            <div className="faq-arrow"></div>
          </div>

          <div className="faq-text">
            {/* Click on{" "}
            <span onClick={modalOpen} style={{ color: "#009AE0" }}>
              Sign up
            </span>{" "} */}
            An Education loan under the programme will cover up to a 100% of{" "}
            <span style={{ fontWeight: "bold" }}>
              ONLY the Course/Tuition Fee
            </span>{" "}
            for all type of courses depending upon the strength of the
            applicant’s profile and credit worthiness of the Co-applicant. The
            programme does not cover additional expenses like Books/Study
            Material cost, Hostel or accommodation cost, Expense for personal
            belongings such as laptop etc.
          </div>
        </label>
        {showMore ? (
          <div>
            <input id="faq-d" type="checkbox" />
            <label for="faq-d">
              <div className="faq-heading">
                <div>
                  <div>4.</div>
                  <div>What is the maximum tenure/duration of the loan ?</div>
                </div>
                <div className="faq-arrow"></div>
              </div>

              <div className="faq-text">
                The maximum tenure of a loan that can be availed under the
                programme the duration of the course fee or 12 months, whichever
                is lower. For ex : For a course whose duration is 6 months, the
                max loan tenure is 6 months.
              </div>
            </label>
            <input id="faq-e" type="checkbox" />
            <label for="faq-e">
              <div className="faq-heading">
                <div>
                  <div>5.</div>
                  <div>
                    {" "}
                    How much time does it take to process an application ?
                  </div>
                </div>
                <div className="faq-arrow"></div>
              </div>

              <div className="faq-text">
                Classplus/Associated lending partners will convey their decision
                within 48 hrs (T+2 days) for applicants choosing to apply via
                any recognized or partnered Institute, provided the application
                is complete in all respects.
              </div>
            </label>
            <input id="faq-f" type="checkbox" />
            <label for="faq-f">
              <div className="faq-heading">
                <div>
                  <div>6.</div>
                  <div> How and when will the loan be disbursed ?</div>
                </div>
                <div className="faq-arrow"></div>
              </div>

              <div className="faq-text">
                The loan will be disbursed to the the tutors bank account within
                48 hrs (T+2 days) of the of the approval of the loan or within
                96 hrs (T+4 days) of the complete application submission
              </div>
            </label>
            <input id="faq-g" type="checkbox" />
            <label for="faq-g">
              <div className="faq-heading">
                <div>
                  <div>7.</div>
                  <div> When is subvention deducted ?</div>
                </div>
                <div className="faq-arrow"></div>
              </div>

              <div className="faq-text">
                Upon approval of the of the students loan, the student has to
                pay 1st EMI as downpayment. After this, the loan amount gets
                credited to the tutors amount after deduction of subvention.
              </div>
            </label>
            <input id="faq-h" type="checkbox" />
            <label for="faq-h">
              <div className="faq-heading">
                <div>
                  <div>8.</div>
                  <div>
                    {" "}
                    Do educational loans under the Classplus Student EMI
                    programme require any security ?
                  </div>
                </div>
                <div className="faq-arrow"></div>
              </div>

              <div className="faq-text">
                Classplus or associated lending partners do not insist on a
                collateral or security under the programme.
              </div>
            </label>
            <input id="faq-i" type="checkbox" />
            <label for="faq-i">
              <div className="faq-heading">
                <div>
                  <div>9.</div>
                  <div> What is the schedule of repayment of the loan ?</div>
                </div>
                <div className="faq-arrow"></div>
              </div>

              <div className="faq-text">
                The Repayment Schedule will commence immediately from the date
                of disbursal or Commencement of the Course (Whichever is
                earlier) at the Institute with the candidate paying the first
                installment on that date itself.
              </div>
            </label>
            <input id="faq-j" type="checkbox" />
            <label for="faq-j">
              <div className="faq-heading">
                <div>
                  <div>10.</div>
                  <div className="m-left-0">
                    {" "}
                    Are there any additional fees or charges to be paid from the
                    tutor or the student ?
                  </div>
                </div>
                <div className="faq-arrow"></div>
              </div>

              <div className="faq-text p-left">
                No. The tutor pays NO additional charges apart from the
                Subvention & 18% GST on subvention. Similarly, there are no
                additional charges for the students.
              </div>
            </label>
            <input id="faq-k" type="checkbox" />
            <label for="faq-k">
              <div className="faq-heading">
                <div>
                  <div>11.</div>
                  <div className="m-left-0">
                    {" "}
                    What will be the role of the student’s guardian in the
                    entire process ?
                  </div>
                </div>
                <div className="faq-arrow"></div>
              </div>

              <div className="faq-text p-left">
                The parent(s) or guardian of the applicant would be treated as a
                Co-Applicant of this Education Loan if the applicant is not
                working. His or her role would be, necessarily, like the Primary
                Debtor.
              </div>
            </label>
            <input id="faq-l" type="checkbox" />

            <label for="faq-l">
              <div className="faq-heading">
                <div>
                  <div>12.</div>
                  <div className="m-left-0">
                    {" "}
                    Can a tutor share his student documents over mail ?
                  </div>
                </div>
                <div className="faq-arrow"></div>
              </div>

              <div className="faq-text p-left">
                Yes. Tutors can directly send the documents of the students over
                mail to studentemi@classplus.co along with Institute Name,
                Student name, Student contact number, Course Name - for which
                loan is availed, Loan amount.
              </div>
            </label>

            <input id="faq-m" type="checkbox" />
            <label for="faq-m">
              <div className="faq-heading">
                <div>
                  <div>13.</div>
                  <div className="m-left-0">
                    What is difference between the Bank Education Loan and in
                    Classplus Student EMI programme No Cost EMI Offering ?
                  </div>
                </div>
                <div className="faq-arrow"></div>
              </div>

              <div className="faq-text p-left">
                In bank education loan, the applicant needs to provide
                guarantee/collateral along with paying additional rates of
                interest. While in Classplus Student EMI programme, there is NO
                collateral/guarantee to avail loans & NO additional interest
                rates.
              </div>
            </label>

            <input id="faq-n" type="checkbox" />
            <label for="faq-n">
              <div className="faq-heading">
                <div>
                  <div>14.</div>
                  <div className="m-left-0">
                    What is the Refund Policy in case of Cancellation or if the
                    student leaves the institute in the middle of course ? What
                    would be the status of the remaining EMIs in such case ?
                  </div>
                </div>
                <div className="faq-arrow"></div>
              </div>

              <div className="faq-text p-left">
                In case of extreme unforeseen circumstances of a student
                cancelling/leaving the course in between, all remaining EMIs
                shall be paid as per schedule. Classplus or its associated
                lending partners do not offer any refunds on the same.
                <br />
                <br />
                However, it is upon the discretion of the institute to refund
                the amount to the student in any way it deems fit. Classplus
                would have no relation with the same, financially or legally.
              </div>
            </label>
            <input id="settings" type="checkbox" />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="moreFAQ">
        <button className="moreFAQ__btn" onClick={showMoreFAQ}>
          {!showMore ? "See More FAQs" : "See Less FAQs"}
        </button>
      </div>
    </div>
  );
}

export default FAQs;
