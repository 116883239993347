import React, { Component } from "react";
import india from "../images/in.svg";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import FinalFormCourse from "./FinalFormCourse";

class FinalForm extends Component {
  constructor() {
    super();
    this.state = {
      checkboxData: [
        { label: "School Academics (Class 1 - Class 12)", index: 0 },
        { label: "IIT-JEE (Mains/Advanced) / NEET", index: 1 },
        {
          label:
            "State / Central Competitive Examinations (IAS/PCS/Banking/Railways/Defence)",
          index: 2,
        },
        { label: "CA / CS / CMA (Inter / Foundation)", index: 3 },
        { label: "Diploma / Professional Courses", index: 4 },
        { label: "Vocational Courses", index: 5 },
        { label: "Other" },
      ],
      ButtonColor: "#e5e5e5",
      ButtonEnable: false,
      courseList: [
        {
          courseName: "",
          courseDuration: "",
          courseFees: "",
          applicableTaxes: "",
        },
      ],
      instituteDescription: [],
      modeOfTeaching: "",
    };
  }

  success = () => {
    console.log(this.state.instituteDescription, "%%%%%%%institute");
    console.log(this.state.courseList, "%%%%%%%courseList");
    console.log(this.state.modeOfTeaching, "%%%%%%mode");
    const { instituteDescription, courseList, modeOfTeaching } = this.state;
    this.props.submitTutorInformation(
      instituteDescription,
      courseList,
      modeOfTeaching
    );

    // this.props.history.push("/success");
  };

  updateCourseField = (index, type, e) => {
    const { courseList } = this.state;
    const list = [...courseList];

    list[index][type] = e.target.value;

    this.setState(
      {
        courseList: [...list],
      },
      () => {
        this.courseListValidation();
      }
    );
  };

  addFieldToCourseList = () => {
    const { courseList } = this.state;
    const list = [
      ...courseList,
      {
        courseName: "",
        courseDuration: "",
        courseFees: "",
        applicableTaxes: "",
      },
    ];
    this.setState(
      {
        courseList: [...list],
      },
      () => {
        this.addClicked();
      }
    );
  };

  removeFieldFromCourseList = (currentIndex) => {
    const { courseList } = this.state;
    const list = [...courseList];
    list.splice(currentIndex, 1);

    this.setState({
      courseList: [...list],
    });
  };

  addClicked = () => {
    this.setState({
      ButtonEnable: false,
      ButtonColor: "#E5E5E5",
    });
  };

  removeClicked = () => {
    this.setState({
      ButtonEnable: true,
      ButtonColor: "#009ae0",
    });
  };

  // SuccessDisplay = () => {
  //   this.props.displaySuccess()
  //   // this.leadListValidation();
  //   // this.leadsApiCall();
  // };

  courseApiCall = () => {
    const { leadsList } = this.state;

    // const Leads = leadsList.map((lead, i) => {
    //     return {
    //         ownerName: lead.leadName,
    //         mobile: lead.leadNumber,
    //         coachingName: lead.instituteName,
    //         city: lead.city,
    //         state: lead.state,
    //     };
    // });

    // let payload = {
    //     leads: Leads,
    // };

    // fetch(`${baseUrl}/su/affiliate/leads`, {
    //     method: "POST",
    //     headers: {
    //     "Content-Type": "application/json",
    //     "x-access-token": localStorage.getItem("accessToken"),
    //     },
    //     body: JSON.stringify(payload),
    // })
    //     .then((res) => res.json())
    //     .then((res) => {
    //     toast.success("Details Submitted Successfully");
    //     this.props.displaySuccess();
    //     })
    //     .catch((err) => toast.error(err.message));
  };

  courseListValidation = () => {
    const { courseList } = this.state;
    const names = courseList.filter((course) => course.courseName.length >= 3);
    const fees = courseList.filter((course) => course.courseFees >= 1);

    if (
      names.length === courseList.length &&
      fees.length === courseList.length
    ) {
      this.setState({
        ButtonEnable: true,
        ButtonColor: "#009ae0",
      });
    } else {
      if (names.length < courseList.length) {
        this.setState({
          ButtonEnable: false,
          ButtonColor: "#E5E5E5",
        });
        // toast.error("Please make sure all the required Names are valid");
      }

      if (fees.length < courseList.length) {
        this.setState({
          ButtonEnable: false,
          ButtonColor: "#E5E5E5",
        });
      }
    }
  };

  checkboxChange = (e, index, label) => {
    let dummyInstitutes = [
      ...this.state.instituteDescription,
      { index: index, label: label },
    ];

    this.setState({
      instituteDescription: dummyInstitutes,
    });
  };

  render() {
    const { checkboxData, courseList, ButtonColor, ButtonEnable } = this.state;
    return (
      <div className="FinalForm">
        <div className="JoinEMIProgram__Container--Form">
          <div className="JoinEMIProgram__Container--Form__Label">
            Institute Description
          </div>
          <div
            className="JoinEMIProgram__Container--Form__SubLabel"
            style={{ marginBottom: "2.4rem" }}
          >
            Select all that apply
          </div>
          {checkboxData.map((data, index) => {
            return (
              <div className="JoinEMIProgram__Container--Form__RadioButton">
                <input
                  className="JoinEMIProgram__Container--Form__RadioButton--Radio"
                  type="checkbox"
                  onChange={(e) => this.checkboxChange(e, index, data.label)}
                />
                <div className="JoinEMIProgram__Container--Form__RadioButton--Label">
                  {data.label}
                </div>
              </div>
            );
          })}
          <div className="JoinEMIProgram__Container--Form__Label">
            Mode of teaching
          </div>
          <div className="JoinEMIProgram__Container--Form__Mode">
            <div
              className="JoinEMIProgram__Container--Form__Mode--Col"
              onClick={() => {
                this.setState({ modeOfTeaching: "Offline" });
              }}
            >
              <input
                className="JoinEMIProgram__Container--Form__RadioButton--Radio"
                type="radio"
                name="modeOfTeaching"
              />
              <div
                style={{ marginTop: "0.4rem" }}
                className="JoinEMIProgram__Container--Form__RadioButton--Label"
              >
                Offline
              </div>
            </div>
            <div
              className="JoinEMIProgram__Container--Form__Mode--Col"
              onClick={() => {
                this.setState({ modeOfTeaching: "Online" });
              }}
            >
              <input
                className="JoinEMIProgram__Container--Form__RadioButton--Radio"
                type="radio"
                name="modeOfTeaching"
              />
              <div
                style={{ marginTop: "0.4rem" }}
                className="JoinEMIProgram__Container--Form__RadioButton--Label"
              >
                Online
              </div>
            </div>
            <div
              className="JoinEMIProgram__Container--Form__Mode--Col"
              onClick={() => {
                this.setState({ modeOfTeaching: "Both" });
              }}
            >
              <input
                className="JoinEMIProgram__Container--Form__RadioButton--Radio"
                type="radio"
                name="modeOfTeaching"
              />
              <div
                style={{ marginTop: "0.4rem" }}
                className="JoinEMIProgram__Container--Form__RadioButton--Label"
              >
                Both
              </div>
            </div>
          </div>
          <div className="JoinEMIProgram__Container--Form__Label MarginTop">
            Courses offered
          </div>
          <input
            style={{
              border: "none",
              borderBottom: "1px solid #e5e5e5",
              paddingLeft: "0",
            }}
            className="JoinEMIProgram__Container--Form__Input"
            placeholder="Please enter courses with annual fees more than ₹ 15,000"
            disabled={true}
          />
          {courseList.map((coursefield, i) => {
            return (
              <FinalFormCourse
                key={i}
                coursefield={coursefield}
                currentIndex={i}
                courseList={courseList}
                updateCourseField={this.updateCourseField}
                addFieldToCourseList={this.addFieldToCourseList}
                removeFieldFromCourseList={this.removeFieldFromCourseList}
                addClicked={this.addClicked}
                removeClicked={this.removeClicked}
              />
            );
          })}
          {/* <div className="JoinEMIProgram__Container--Form__Footer">
                        <div className="JoinEMIProgram__Container--Form__Footer--Para">Want to add more courses? 1 of 10 added</div>
                        <button className="JoinEMIProgram__Container--Form__Footer--Button">+ ADD</button>
                    </div> */}
          <button
            disabled={!ButtonEnable}
            style={{ background: ButtonColor }}
            onClick={this.success}
            className="JoinEMIProgram__Container--Button"
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(FinalForm);
