import React, { Component } from "react";
import Navigation from "./Navigation";
import Footer from "./Footer";
import LandingMain from "./LandingMain";
import ProgramDetails from "./ProgramDetails";
import ProgramBenefits from "./ProgramBenefits";
import FeesCalculator from "./FeesCalculator";
import FAQs from "./FAQs";
import ContactUs from "./ContactUs";
import DocumentsRequired from "./DocumentsRequired";
import LoanProcessFlow from "./LoanProcessFlow";
import { withRouter } from "react-router-dom";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      isScroll: false,
    };
  }

  componentDidMount() {
    window.onscroll = () => {
      if (document.documentElement.scrollTop > 600) {
        this.setState({ isScroll: true });
      } else {
        this.setState({ isScroll: false });
      }
    };
  }

  openRegister = () => {
    this.props.history.push("/join-program");
  };

  render() {
    const { isScroll } = this.state;
    return (
      <div className="Home">
        <Navigation isScroll={isScroll} openRegister={this.openRegister} />
        <LandingMain openRegister={this.openRegister} />
        <ProgramDetails />
        <ProgramBenefits />
        <FeesCalculator />
        <LoanProcessFlow />
        <DocumentsRequired />
        <FAQs />
        <ContactUs />
        <Footer />
        <div
          className="Home-Footer"
          id="Home-Footer"
          style={{ display: isScroll ? "flex" : "none" }}
        >
          <button
            className="Home-Footer-btn"
            onClick={() => {
              this.props.history.push("/join-program");
            }}
          >
            Register Now
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(Home);
