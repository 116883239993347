import React, { Component } from 'react';
import landingImg from "../images/landingRightImg.svg";
import landingImgMobile from "../images/landingMobile.svg";

class LandingMain extends Component {
    render(){
        return(
            <div className="LandingMain">
                <div className="LandingMain__Col1">
                    <div className="LandingMain__Col1--Para1">Classplus Student EMI Program</div>
                    <div className="LandingMain__Col1--Para2">Collect your tuition fee in advance while students pay at No Cost EMI</div>
                    <button onClick={this.props.openRegister} className="LandingMain__Col1--Button">Register Now</button>
                </div>
                <div className="LandingMain__Col2">
                    <img src={landingImg} alt="landingImg" className="LandingMain__Col2--Img" />
                    <img src={landingImgMobile} alt="landingImg" className="LandingMain__Col2--ImgMobile" />
                </div>
            </div>
        )
    }
}

export default LandingMain;