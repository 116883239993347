import React, { Component } from 'react';
import file from "../images/file.svg"
import card from "../images/card.svg"
import location from "../images/location.svg"
import bank from "../images/bank.svg"
import suitcase from "../images/suitcase.svg"


class DocumentsRequired extends Component {
    render(){
        return(
            <div className="DocumentsRequired">
                <div className="DocumentsRequired__Header">Documents Required</div>
                <div className="DocumentsRequired__Para">The student or co-applicant needs to upload these documents</div>
                <div className="DocumentsRequired__Details">
                    <div className="DocumentsRequired__Details--Content">
                        <div className="DocumentsRequired__Details--Content__Para">1. PAN Card</div>
                        <img src={file} alt="file" className="DocumentsRequired__Details--Content__Img"/>
                    </div>
                    <div className="DocumentsRequired__Details--Content">
                        <div className="DocumentsRequired__Details--Content__Para">2. Aadhar Card</div>
                        <img src={card} alt="card" className="DocumentsRequired__Details--Content__Img"/>
                    </div>
                    <div className="DocumentsRequired__Details--Content">
                        <div className="DocumentsRequired__Details--Content__ParaDiv">
                            <div className="DocumentsRequired__Details--Content__Para">3. Address proof</div>
                            <div className="DocumentsRequired__Details--Content__Para2">Electricity/water/landline/gas bill, passport, voter ID, etc</div>
                        </div>
                        <img src={location} alt="location" className="DocumentsRequired__Details--Content__Img"/>
                    </div>
                </div>
                <div className="DocumentsRequired__Details2">
                    <div className="DocumentsRequired__Details2--Content">
                        <div className="DocumentsRequired__Details2--Content__ParaDiv">
                            <div className="DocumentsRequired__Details2--Content__Para">4 (a) For Salaried</div>
                            <div className="DocumentsRequired__Details2--Content__Para2">3 months bank statement</div>
                        </div>
                        <img src={bank} alt="bank" className="DocumentsRequired__Details2--Content__Img"/>
                    </div>
                    <div className="DocumentsRequired__Details2--Content">
                        <div className="DocumentsRequired__Details2--Content__ParaDiv">
                            <div className="DocumentsRequired__Details2--Content__Para">4 (b) For Self Employed</div>
                            <div className="DocumentsRequired__Details2--Content__Para2">6 month bank statement + GST Certificate/Business PAN details/ Shop Act Certificate</div>
                        </div>
                        <img src={suitcase} alt="suitcase" className="DocumentsRequired__Details2--Content__Img"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default DocumentsRequired;