import React, { Component } from "react";
import next from "../images/next.svg";
import mobileLogo from "../images/mobileLogo.svg";
import { toast } from "react-toastify";
import StudentModalBody from "./StudentModalBody";
import { Link } from "react-router-dom";
import { baseUrl } from "../config";
import formImage from "../images/formImage.svg";

class StudentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ButtonColor: "#E5E5E5",
      ButtonEnable: false,
      isinstituteErrorMsg: false,
      isPhoneErrorMsg: false,
      isbuisnessErrorMsg: false,
      isnameErrorMsg: false,
      iscityErrorMsg: false,
      addClick: false,
      leadsList: [
        {
          leadName: "",
          leadNumber: "",
          leadEmail: "",
          coApplicantRequired: "",
          coApplicantName: "",
          coApplicantNumber: "",
          coApplicantMail: "",
        },
      ],
      load: true,
    };
  }

  updateLeadField = (index, type, e) => {
    const { leadsList } = this.state;
    const list = [...leadsList];
    if (e.target) {
      list[index][type] = e.target.value;
    } else {
      list[index][type] = e;
    }

    this.setState(
      {
        leadsList: [...list],
      },
      () => {
        this.leadListValidation();
      }
    );
  };

  addFieldToLeadsList = () => {
    const { leadsList } = this.state;
    const list = [
      ...leadsList,
      {
        leadName: "",
        leadNumber: "",
        instituteName: "",
        city: "",
        state: "",
        above40Students: "",
      },
    ];

    this.setState({
      leadsList: [...list],
    });
  };

  removeFieldFromLeadsList = (currentIndex) => {
    const { leadsList } = this.state;
    const list = [...leadsList];
    list.splice(currentIndex, 1);

    this.setState({
      leadsList: [...list],
    });
  };

  addClicked = () => {
    this.setState({
      ButtonEnable: false,
      ButtonColor: "#E5E5E5",
    });
  };

  removeClicked = () => {
    this.setState({
      ButtonEnable: true,
      ButtonColor: "#009ae0",
    });
  };

  leadListValidation = () => {
    const { leadsList } = this.state;
    const names = leadsList.filter((lead) => lead.leadName.length >= 3);

    if (names.length === leadsList.length) {
      this.setState({
        ButtonEnable: true,
        ButtonColor: "#009ae0",
      });
    } else {
      if (names.length < leadsList.length) {
        this.setState({
          ButtonEnable: false,
          ButtonColor: "#E5E5E5",
        });
        // toast.error("Please make sure all the required Names are valid");
      }
    }
  };

  submitStudentData = async () => {
    const { leadsList } = this.state;

    let name = localStorage.getItem("tutorName");
    let number = localStorage.getItem("tutorNumber");

    for (const lead of leadsList) {
      try {
        const response = await fetch(
          "https://v1.nocodeapi.com/classplus/google_sheets/xjQkrinSFNkXNTCm?tabId=Sheet2",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify([
              [
                name,
                number,
                JSON.stringify(lead.leadName),
                JSON.stringify(lead.leadNumber),
                JSON.stringify(lead.leadEmail),
                JSON.stringify(lead.coApplicantRequired),
                JSON.stringify(lead.coApplicantName),
                JSON.stringify(lead.coApplicantNumber),
                JSON.stringify(lead.coApplicantMail),
              ],
            ]),
          }
        );

        await response.json();
      } catch (err) {
        console.log(err);
      }
    }
    this.props.displaySuccess();
  };

  render() {
    let { ButtonColor, ButtonEnable, leadsList } = this.state;
    return (
      <div className="LeadsModal">
        <div className="LeadsModal__modal">
          <div className="LeadsModal__mobileModal">
            <div className="LeadsModal__mobileModal--Row1">
              <Link to="/">
                <img
                  className="LeadsModal--next"
                  alt="next"
                  src={next}
                  // onClick={this.props.closeModal}
                />
              </Link>

              <img alt="logo" src={mobileLogo} />
            </div>
            <div className="LeadsModal__mobileModal--Row2">
              <p>Student Details for EMI facility</p>
            </div>
          </div>
          <div className="LeadsModal__modalContainer">
            <div
              className="LeadsModal__mobileHeader"
              style={{ marginTop: "15px" }}
            ></div>

            {/* <div className="LeadsModal__modalHeader">
              <p className="LeadsModal__modalHeader--Para">Sign up</p>
              <img
                className="LeadsModal--Close"
                src={Close}
                alt="Close"
                onClick={this.props.closeModal}
              />
            </div> */}
            <div className="LeadsModal__modalBody--modalTopSection">
              <img src={formImage} alt="" />
              <div className="LeadsModal__modalBody--modalTopSection-Text">
                <div className="LeadsModal__modalBody--modalTopSection-Text-Heading">
                  Add details of students who wish to avail EMI facility
                </div>
                <div className="LeadsModal__modalBody--modalTopSection-Text-SubText">
                  Students will have to upload required documents for approval
                  of EMI facility
                </div>
              </div>
            </div>

            {leadsList.map((leadsfield, i) => {
              return (
                <StudentModalBody
                  key={i}
                  leadsfield={leadsfield}
                  currentIndex={i}
                  leadsList={leadsList}
                  updateLeadField={this.updateLeadField}
                  addFieldToLeadsList={this.addFieldToLeadsList}
                  removeFieldFromLeadsList={this.removeFieldFromLeadsList}
                  addClicked={this.addClicked}
                  removeClicked={this.removeClicked}
                  stateResponse={this.props.stateResponse}
                  cityResponse={this.props.cityResponse}
                />
              );
            })}

            <div className="LeadsModal__modalFooter">
              <button
                style={{ background: ButtonColor }}
                onClick={this.submitStudentData}
                // disabled={!ButtonEnable}
                className="LeadsModal__modalFooter--SaveBtn"
              >
                Add Details
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StudentModal;
