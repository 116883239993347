import React, { Component } from "react";

class ProgramDetails extends Component {
  render() {
    return (
      <div className="ProgramDetails">
        <div className="ProgramDetails__Header">
          What is Classplus Student EMI Program?
        </div>
        <div className="ProgramDetails__Para">
          The program enables you to provide financial support to your students.
          Your students can now pay their tuition fee in affordable monthly
          installments with <span>zero interest</span>. This means that the
          students would only be paying for the total price of the course, with
          no extra charges.
        </div>
      </div>
    );
  }
}

export default ProgramDetails;
