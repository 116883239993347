import React, { Component } from 'react';
import moneyFlow from "../images/money-flow.svg" 
import onlinePayment from "../images/online-payment.svg" 
import student from "../images/student.svg" 
import decreasing from "../images/decreasing.svg" 

class ProgramBenefits extends Component {
    render(){
        return(
            <div className="ProgramBenefits">
                <div className="ProgramBenefits__Header">Benefits of Classplus Student EMI Program</div>
                <div className="ProgramBenefits__Body">
                    <div className="ProgramBenefits__Body--Content1">
                        <div className="ProgramBenefits__Body--Para">Better cash-flows</div>
                        <img src={moneyFlow} alt="moneyFlow" className="ProgramBenefits__Body--Img" />
                    </div>
                    <div className="ProgramBenefits__Body--Content2">
                        <div className="ProgramBenefits__Body--Para">Increase enrolments</div>
                        <img src={student} alt="student" className="ProgramBenefits__Body--Img" />
                    </div>
                    <div className="ProgramBenefits__Body--Content3">
                        <div className="ProgramBenefits__Body--Para">No hassle of fee collection</div>
                        <img src={onlinePayment} alt="onlinePayment" className="ProgramBenefits__Body--Img" />
                    </div>
                    <div className="ProgramBenefits__Body--Content4">
                        <div className="ProgramBenefits__Body--Para">Reduce Drop-Outs</div>    
                        <img src={decreasing} alt="decreasing" className="ProgramBenefits__Body--Img" />
                    </div>
                </div>
            </div>
        )
    }
}

export default ProgramBenefits;